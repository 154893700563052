import React from "react";

export default function NetworksInfo({networks}) {
    return (
        <div>
            { networks && networks.length > 0 &&
            <table className="statistics">
                <tbody>
                    <tr>
                        <td>{networks.length}</td>
                        <td>monitoring programmes</td>
                    </tr>
                </tbody>
            </table>
            }
        </div>
    );
}
