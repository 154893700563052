import React from "react";
import config from "../config";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export default function Header() {
  return <div id="header" className="py-2 px-4 flex justify-end items-center">
    <div id="brand" className="mr-auto">
        <Link to="/" className="text-lg font-bold leading-tight">
            <img alt="IOC logo" className="mr-5 ml-1" src="ioc.png" />
            <h1 className="text-black">GOOS BioEco Metadata Portal</h1>
        </Link>
    </div>
    <span className="mr-auto"><Button variant="outlined" color="error">Under development</Button></span>
    <Link to="/statistics" className=" text-gray-700">Statistics</Link>
    <Link to="/about" className="ml-6 text-gray-700">About</Link>
    <a href="https://iobis.github.io/bioeco-docs/" rel="noopener noreferrer" target="_blank" className="ml-6 text-gray-700">User Guide</a>
    <a href={config.admin_url} target="_blank" rel="noopener noreferrer" className="ml-6  text-gray-700">Open GeoNode</a>
  </div>
}
