import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Map from "./Map";
import SideBar from "./SideBar";
import DetailBar from "./DetailBar";
import * as util from "../util/util";
import * as data from "../util/data";

function App() {

    const [variables, setVariables] = useState([]);
    const [subvariables, setSubvariables] = useState([]);
    const [readinessKeywords, setReadinessKeywords] = useState([]);
    const [networks, setNetworks] = useState([]);
    const [network, setNetwork] = useState(null);
    const [geoServerQuery, setGeoServerQuery] = useState("");
    const [search, setSearch] = useState("");
    const [readiness, setReadiness] = useState(["", "", ""]);
    const [bbox, setBbox] = useState(null);
    const [inOBIS, setInOBIS] = useState(false);

    function handleInOBISChange(e) {
        setInOBIS(e.target.checked);
    }

    /**
     * Handles change in readiness selector.
     */
    function handleReadinessChange(k, v) {
        const newReadiness = [...readiness];
        newReadiness[k] = v;
        setReadiness(newReadiness);
    }

    /**
     * Handles opening network detail.
     */
    async function handleSetNetwork(id) {
        if (!network || network.id !== id) {
            const n = await data.fetchNetwork(id);
            setNetwork(n);
        }
    }

    /**
     * Handles search changes.
     */
    function handleSearchChange(e) {
        const term = e.target.value;
        setSearch(term);

        const newNetworks = networks.map(network => {
           let hide = false;
           if (term && term !== "" && !network.name.toLowerCase().includes(term.toLowerCase()) && !network.title.toLowerCase().includes(term.toLowerCase())) {
               hide = true;
           }
           return {
               ...network,
               hide: hide
           }
        });
        setNetworks(newNetworks);
    }

    /**
     * Handles change in a variable select.
     */
    function handleVariableChange(e) {
        let newVariables;
        if (e === true) {
            newVariables = variables.map(variable => {
                return { ...variable, checked: true }
            });
        } else if (e === false) {
            newVariables = variables.map(variable => {
                return { ...variable, checked: false }
            });
        } else {
            newVariables = variables.map(variable => {
                if (variable.id.toString() === e.target.name) {
                    return { ...variable, checked: !variable.checked }
                } else {
                    return variable;
                }
            });
        }
        setVariables(newVariables);
    }

    function handleSubvariableChange(e) {
        let newSubvariables;
        if (e === true) {
            newSubvariables = subvariables.map(subvariable => {
                return { ...subvariable, checked: true }
            });
        } else if (e === false) {
            newSubvariables = subvariables.map(subvariable => {
                return { ...subvariable, checked: false }
            });
        } else {
            newSubvariables = subvariables.map(sv => {
                if (e.target.value.includes(sv.id)) {
                    sv.checked = true;
                } else {
                    sv.checked = false;
                }
                return sv;
            });
        }
        setSubvariables(newSubvariables);
    }

    useEffect(() => {
        /**
         * Initialize variables.
         */
         async function initVariables() {
            const [v, sv] = await data.fetchVariables();
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });
            if (params.variables) {
                const variabledIds = params.variables.replace(/, +/g, ",").split(",").map(Number);
                v.forEach(variable => {
                    if (variabledIds.includes(variable.id)) {
                        variable.checked = true;
                    }
                });
            }
            if (params.subvariables) {
                const subvariabledIds = params.subvariables.replace(/, +/g, ",").split(",").map(Number);
                sv.forEach(subvariable => {
                    if (subvariabledIds.includes(subvariable.id)) {
                        subvariable.checked = true;
                    }
                });
            }
            setVariables(v);
            setSubvariables(sv);
        }
        async function initReadinessKeywords() {
            const r = await data.fetchReadiness();
            setReadinessKeywords(r);
        }
        async function initialize() {
            await initReadinessKeywords(); // only for building queries
            initVariables();
        }
        initialize();
    }, []);

    function updateURL() {
        const params = new URLSearchParams();
        if (variables && variables.some(v => v.checked === true)) {
            params.append("variables", variables.filter(v => v.checked === true).map(v => v.id).join(","));
        }
        if (subvariables && subvariables.some(v => v.checked === true)) {
            params.append("subvariables", subvariables.filter(v => v.checked === true).map(v => v.id).join(","));
        }
        if (inOBIS) {
            //params.append("in_obis", "true");
            // todo: support parsing from URL
        }
        if (Array.from(params).length) {
            window.history.replaceState(null, "", "?" + params.toString());
        } else {
            window.history.replaceState(null, "", "/");
        }
    }

    /**
     * Handles filter side effects.
     */
    async function processFilters() {
        let query = util.buildAPIQuery(variables, subvariables, readiness, readinessKeywords, inOBIS);
        let geoServerQuery = util.buildGeoServerQuery(variables, subvariables, readiness, readinessKeywords, inOBIS);
        let d = await data.fetchNetworks(query);
        ReactDOM.unstable_batchedUpdates(async () => {
            setGeoServerQuery(geoServerQuery);
            setNetwork(null);
            setNetworks(d.layers);
        });
    }

    useEffect(() => {
        processFilters();
    }, [readiness]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (inOBIS !== null) {
            processFilters();
            updateURL();
        }
    }, [inOBIS]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (variables.length > 0) {
            processFilters();
            updateURL();
        }
    }, [variables]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (subvariables.length > 0) {
            processFilters();
            updateURL();
        }
    }, [subvariables]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const detail = document.querySelector("#detailbar");
        if (detail) {
            detail.scrollIntoView({ behavior: "smooth" }, 500);
        }
    }, [network]);

    return (
        <div id="app">
            <div id="sidebar-wrapper">
                { network &&
                    <DetailBar variables={variables} network={network} handleDetailBarClose={() => setNetwork(null) } setBbox={setBbox} />
                }
                <SideBar inOBIS={inOBIS} handleInOBISChange={handleInOBISChange} handleReadinessChange={handleReadinessChange} readiness={readiness} variables={variables} subvariables={subvariables} networks={networks} search={search} handleSubvariableChange={handleSubvariableChange} handleSearchChange={handleSearchChange} handleVariableChange={handleVariableChange} handleSetNetwork={handleSetNetwork} />
            </div>
            <Map networks={networks} network={network} geoServerQuery={geoServerQuery} handleSetNetwork={handleSetNetwork} bbox={bbox} />
        </div>
    );
}

export default App;
