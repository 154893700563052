import { Container } from "@mui/system";
import React from "react";

export default function About() {
  return <Container maxWidth="md" className="py-10 prose">

    <h2>About the portal</h2>

    <h3>Vision &amp; Purpose</h3>

    <p>The GOOS BioEco portal is an open access, online platform that provides information on the sustained ocean observing programs which deliver information about the health of marine life. It provides metadata and information on global ocean observations and monitoring programs of biological and ecosystem (BioEco) Essential Ocean Variables (EOV's). These BioEco EOVs address the information needs of many international conventions and multilateral agreements, and more details about the survey can be found in <a href="https://www.frontiersin.org/articles/10.3389/fmars.2021.737416/full" rel="noopener noreferrer" target="_blank">Frontiers in Marine Science</a>. The 12 <a href="https://www.goosocean.org/index.php?option=com_content&view=article&id=283&Itemid=441" rel="noopener noreferrer" target="_blank">BioEco EOVs</a> currently included in the Portal, are:</p> 

    <ul>
      <li>Microbe biomass and diversity</li>
      <li>Phytoplankton biomass and diversity</li>
      <li>Zooplankton biomass and diversity</li>
      <li>Benthic invertebrate biomass and diversity</li>
      <li>Fish abundance and distribution</li>
      <li>Marine birds abundance and distribution</li>
      <li>Marine mammals abundance and distribution</li>
      <li>Marine turtles abundance and distribution</li>
      <li>Macroalgal cover and composition</li>
      <li>Mangrove cover and composition</li>
      <li>Hard coral cover and composition</li>
      <li>Seagrass cover and composition</li>
    </ul>

    <p>The metadata contained in the Portal allows us to know who is measuring what, where, how and why for marine biodiversity, as a fundamental starting point to coordinate and enhance observations, including filling the gaps. </p>

    <p>Life in the ocean is an important source of food and livelihoods, and our societies depend on it for economic, cultural, social and environmental wellbeing. However, unsustainable use of marine resources coupled with climate change impacts is causing massive changes in the ocean, and many of the benefits that humans derive from marine biodiversity are being jeopardised. Improved management of human activities that impact ocean life is crucial, and to achieve this we must understand how marine biodiversity is distributed, where and how rapidly decline is happening, and what is causing this decline.</p>

    <p>There is an increasing need for systems that can enable data from sustained biological and ecological ocean observations to be shared globally, with established standardisation of methods and data processing. The BioEco Portal serves as a tool that the ocean observing community can use to discover information on marine biological and ecosystem observations and the sustained programmes that collect them.</p>

    <h3>A 'one of a kind' resource</h3>

    <p>The portal is developed in collaboration with the OBIS, IOC Project Office for International Oceanographic Data and Information Exchange, the GOOS BioEco Panel, and the data centre of the Flanders Marine Institute. It serves as a unique online tool for information on programmes that collect observations of the BioEco EOVs. This is a one of a kind resource for marine life researchers globally that will help the BioEco Panel find gaps and support targeted action in areas where sustained ocean observing is needed, and where we know ocean stressors are at work.</p>

    <p>GOOS encourages visitors of the portal and registered users to call upon their networks and colleagues to contribute, regardless of the scale or developmental stage of their ocean observing programme.</p>

    <h3>Future milestones</h3>

    <p>One of the future goals of the BioEco Portal is to create an automated flow of data and metadata from ocean observing programmes to the portal and data management systems such as OBIS. By 2025, we are aiming for 90% of active BioEco monitoring programmes having up-to-date entries in the BioEco portal and 80% having established continuous data flow to OBIS. By 2025, the BioEco Portal will also have a live connection with the GOOS monitoring facility hosted by <a href="https://www.ocean-ops.org/board" rel="noopener noreferrer" target="_blank">OceanOPS</a> in Brest.</p>

    <p><img src="milestones.png" alt="milestones" /></p>

    <h3>How to use and contribute to the portal?</h3>

    <p>The portal provides an interactive map that delivers a global picture of the biological and ecosystem observations collected by contributing programmes, which can be selected or filtered by the programme name. The information about each programme includes the variables observed, the state of development of the programme, the standardisations and specifications used to collect observations, and the programme’s observing capability (or readiness level). This information is known as the program ‘metadata’. The actual data collected by each programme can be found in data systems such as the OBIS, and links to them can be added in the portal.</p>

    <p>All metadata and information available in the portal is uploaded through the backend platform, the <a href="https://geonode.goosocean.org/" rel="noopener noreferrer" target="_blank">GOOS BioEco GeoNode</a>, to ensure a user/data provider driven approach. In GeoNode, registered users can upload, edit and manage contributed monitoring programme details and the associated metadata themselves.</p>

    <p>This makes contributing a new programme to the portal quick and straightforward. It can be done independently, using the <a href="https://iobis.github.io/bioeco-docs/" rel="noopener noreferrer" target="_blank">Portal and GeoNode documentation</a> available to all users and visitors. This documentation contains step-by-step instructions and guidelines on how to upload, edit or manage the metadata, supported by videos and examples.</p>

    <p>Newly contributed programmes and updated programme metadata will automatically be incorporated into the GOOS BioEco portal, where users can then observe, compare and connect with programmes, researchers and institutions of interest.</p>

    <h3>Support &amp; Funding</h3>

    <p>The development of the portal has been made possible through financial support from the project "Defining the observing system for the world's oceans - from microbes to whales", funded under the PEGASuS 2 call on Ocean Sustainability, sponsored by Future Earth and the National Center for Ecological Analysis and Synthesis (NCEAS). The Global Ocean Observing System (GOOS) has also provided funds to further develop and improve the content of the portal in 2021-2022.</p>

    <h3>Partnerships</h3>

    <p>This portal is developed in collaboration between the secretariat of the Ocean Biodiversity Information System of the IOC Project Office for IODE and the data centre of the Flanders Marine Institute (VLIZ).</p>

    <p><img src="partnerships.png" alt="partnerships" /></p>

  </Container>
}
