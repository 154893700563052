/* eslint import/no-webpack-loader-syntax: off */
import React, {useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as data from "../util/data";
//import * as util from "../util/util";

/**
 * Creates a map object.
 */
function createMap(mapRef) {
    console.debug("%cInitializing map object", "color:green;font-weight:bold")
    mapboxgl.accessToken = "pk.eyJ1IjoiaW9kZXBvIiwiYSI6ImNrd2txMXRyaTFpNjkybm1sZWxwemtrbWsifQ.KtiKSQsLSwvnDtfg9T9qdA";
    const map = new mapboxgl.Map({
        container: mapRef.current,
        zoom: 3,
        center: [20, 35],
        "style": "mapbox://styles/iodepo/ckwkqelyr1w1514o593l4v53t"
    });
    map.addControl(new mapboxgl.NavigationControl());
    map.getCanvas().style.cursor = "crosshair";
    return map;
}

/**
 * Removes layers by name.
 */
function removeLayers(map, layers) {
    layers.forEach(layer => {
        const layerInstance = map.getLayer(layer);
        if (typeof layerInstance !== "undefined") {
            map.removeLayer(layer);
        }
    });
}

/**
 * Removes the networks layer from map.
 */
function removeNetworksLayer(map) {
    removeLayers(map, ["networks-polygons", "networks-points", "networks-lines", "network-polygons", "network-points", "network-lines"])
    const source = map.getSource("networks");
    if (typeof source !== "undefined") {
        map.removeSource("networks");
    }
}

/**
 * Adds the networks layer to map.
 */
function addNetworksLayer(map, geoServerQuery) {
    let col = "#ffffff";
    let url = data.makeWmtsUrl(geoServerQuery);
    map.addSource("networks", {
        "type": "vector",
        "minZoom": 0,
        "maxZoom": 14,
        "tiles": [url]
    });
    map.addLayer({
        "id": "networks-lines",
        "type": "line",
        "source": "networks",
        "source-layer": "all_layers",
        "paint": {
            "line-opacity": 0.5,
            "line-color": col,
            "line-width": 1
        },
        "filter": ["==", "$type", "LineString"]
    });
    map.addLayer({
        "id": "networks-points",
        "type": "circle",
        "source": "networks",
        "source-layer": "all_layers",
        "paint": {
            "circle-radius": 2,
            "circle-color": "#000000",
            "circle-stroke-color": col,
            "circle-stroke-width": 1,
            "circle-opacity": 0,
            "circle-stroke-opacity": 1
        },
        "filter": ["==", "$type", "Point"]
    });
    map.addLayer({
        "id": "networks-polygons",
        "type": "line",
        "source": "networks",
        "source-layer": "all_layers",
        "paint": {
            "line-opacity": 0.5,
            "line-color": col,
            "line-width": 1
        },
        "filter": ["==", "$type", "Polygon"]
    });
    if (map.getLayer("network")) map.moveLayer("network");
}

/**
 * Removes the network layer from map.
 */
 function removeNetworkLayer(map) {
    removeLayers(map, ["network-polygons", "network-points", "network-lines"]);
}

function addNetworkLayer(map, network) {
    let col = "#ffcc00";
    if (network) {
        map.addLayer({
            "id": "network-polygons",
            "type": "line",
            "source": "networks",
            "source-layer": "all_layers",
            "paint": {
                "line-opacity": 1,
                "line-color": col,
                "line-width": 2
            },
            "filter": ["all", ["==", "$type", "Polygon"], ["==", "name", network.name]]
        });
        map.addLayer({
            "id": "network-points",
            "type": "circle",
            "source": "networks",
            "source-layer": "all_layers",
            "paint": {
                "circle-radius": 2,
                "circle-color": "#000000",
                "circle-stroke-color": col,
                "circle-stroke-width": 1.2,
                "circle-opacity": 0,
                "circle-stroke-opacity": 1
            },
            "filter": ["all", ["==", "$type", "Point"], ["==", "name", network.name]]
        });
        map.addLayer({
            "id": "network-lines",
            "type": "line",
            "source": "networks",
            "source-layer": "all_layers",
            "paint": {
                "line-opacity": 1,
                "line-color": col,
                "line-width": 2
            },
            "filter": ["all", ["==", "$type", "LineString"], ["==", "name", network.name]]
        });
        map.moveLayer("network-polygons");
        map.moveLayer("network-lines");
        map.moveLayer("network-points");
    }
}

function renderPopup(networks, callback) {
    return <div>
        { networks.map(network => <div className="cursor-pointer" key={network.name} onClick={() => callback(network.pk)}>{network.title}</div>) }
    </div>
}

export default function Map({networks, network, geoServerQuery, handleSetNetwork, bbox}) {

    const mapRef = useRef(null);
    const handlerRef = useRef(clickHandler);
    const [map, setMap] = useState(null);
    const [loading, setLoading] = useState(false);

    /**
     * Handles map click.
     */
    async function clickHandler(e) {
        const poi = {
            x: e.lngLat.lng,
            y: e.lngLat.lat,
            z: e.target.getZoom()
        }
        const poiFeatures = await data.fetchNetworksForPoi(poi, geoServerQuery);
        const ids = [...new Set(poiFeatures.features.map(x => x.properties.name))];
        
        const poiNetworks = networks.filter(n => ids.includes(n.name));
        if (poiNetworks.length && map) {
            const popup = new mapboxgl.Popup({ closeOnClick: true, maxWidth: 600 })
                .setLngLat([poi.x, poi.y])
                .setHTML("<div class=\"content\"></div>")
                .addTo(map);

            const popupContentElement = popup.getElement().querySelector(".content");
            ReactDOM.render(renderPopup(poiNetworks, handleSetNetwork), popupContentElement);
        }

    };
    handlerRef.current = clickHandler;

    useEffect(() => {
        /**
         * Initializes the map, runs only once.
         */
        async function initMap() {
            const map = await createMap(mapRef);
            map.on("click", e => handlerRef.current(e));
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                setMap(map);
            }, 2000);
        }
        initMap();
    }, [setMap]); // adding handleClickPoi results in multiple runs

    useEffect(() => {
        /**
         * Updates the networks layer and click handler when the GeoServer query changes.
         */
        function updateMap() {
            if (map) {
                removeNetworksLayer(map);
                addNetworksLayer(map, geoServerQuery);
            }
        }
        updateMap();
    }, [geoServerQuery, map]);

    useEffect(() => {
        /**
         * Updates the network layer when the selected network changes.
         */
        function updateMap() {
            if (map) {
                removeNetworkLayer(map);
                addNetworkLayer(map, network);
            }
        }
        updateMap();
    }, [network, map]);

    useEffect(() => {
        if (map && bbox && bbox.coordinates[0][0][0] !== 0) {
            let bounds = [
                bbox.coordinates[0][0],
                bbox.coordinates[0][2]
            ];
            map.fitBounds(bounds, { padding: 100 });
        }
    }, [bbox, map]);

    return (
        <div id="map" ref={mapRef}>
            { loading && <div className="loader"></div> }
        </div>
    );
}
