import React from "react";
import * as util from "../util/util";
import { Select, MenuItem } from "@mui/material";

export default function ReadinessPicker({value, onChange}) {
    return <Select sx={{ m: 0.5, width: 250 }} size="small" value={value} onChange={(e) => onChange(e.target.value)}>
        <MenuItem value={""}>---</MenuItem>
        {
            Object.entries(util.readinessLabels).map(([k, v]) =>
                <MenuItem key={k} value={k}>{v}</MenuItem>
            )
        }
    </Select>
}