import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Header from "./components/Header";
import App from "./components/App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Routes, Route, HashRouter } from "react-router-dom";
import About from "./components/About";
import Disclaimer from "./components/Disclaimer";
import Statistics from "./components/Statistics";


const theme = createTheme({
    palette: {
        primary: {
            main: "#5D757E",
        },
        dark: {
            main: "#000000"
        },
        secondary: {
            light: '#0066ff',
            main: '#0044ff',
        },

    },
    typography: {
        fontSize: 12,
        fontFamily: "Open Sans"
    }
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <HashRouter>
            <div id="wrapper">
                <Header/>
                <Routes>
                    <Route exact path="/" element={<App />} />
                    <Route exact path="/about" element={<About />} />
                    <Route exact path="/statistics" element={<Statistics />} />
                </Routes>
            </div>

            <Disclaimer></Disclaimer>

            </HashRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
