import React from "react";
import NetworksListItem from "./NetworksListItem";

export default function NetworksList({networks, handleSetNetwork}) {
    return (
        <div id="networks">
            { networks.filter(network => !network.hide).map(network =>
                <NetworksListItem network={network} key={network.pk} handleSetNetwork={handleSetNetwork} />
            )}
        </div>
    );
}
