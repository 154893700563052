import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Disclaimer() {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Disclaimer
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>Welcome to version 1 of the GOOS BioEco portal! As we continue to build on the database of the portal, you might find unpopulated fields or incomplete metadata when selecting or filtering for specific variables. Please bear with us while we are actively gathering and populating the accurate and up to date metadata and information required. Check back in later to see the progress!</p>
            <p className="mt-4">The association of EOVs and subvariables to programs is self-declared by the program metadata providers without a centralised validation process. Standardisation of BioEco EOVs is still a work in progress and it is therefore important to realise that although programs that are measuring the same EOV, they may actually collect totally different kinds of data related to that EOV. The addition of subvariables to describe the programs helps in further illustrating the differences vs commonalities.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}