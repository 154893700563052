import { Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import * as data from "../util/data";
import { Button } from "@mui/material";
import { NavHashLink } from "react-router-hash-link";

export default function About() {

  const [keywordStatistics, setKeywordStatistics] = useState(null);
  const [statistics, setStatistics] = useState(null);

  useEffect(() => {
    async function fetchKeywordStatistics() {
      const res = await data.fetchTKeywords();
      setKeywordStatistics(res);
    }
    async function fetchStatistics() {
      const res = await data.fetchStatistics();
      setStatistics(res);
    }
    fetchKeywordStatistics();
    fetchStatistics();
  }, []);
   

  return <Container maxWidth="md" className="py-10 prose">

    <h2>Statistics</h2>

    <NavHashLink to="#availability" className="no-underline"><Button variant="outlined" color="primary">Data availability</Button></NavHashLink>
    <NavHashLink to="#variables" className="ml-3 no-underline"><Button variant="outlined" color="primary">Variables</Button></NavHashLink>
    <NavHashLink to="#subvariables" className="ml-3 no-underline"><Button variant="outlined" color="primary">Subvariables</Button></NavHashLink>
    <NavHashLink to="#readiness" className="ml-3 no-underline"><Button variant="outlined" color="primary">Readiness</Button></NavHashLink>

    {
      !keywordStatistics && <p><i>Loading...</i></p>
    }

    {
      statistics && <div>
        <table id="availability">
          <thead>
            <tr>
              <th>Data availability</th>
              <th>Programmes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Data in OBIS</td>
              <td>{statistics.in_obis_true}</td>
            </tr>
            <tr>
              <td>Data not in OBIS</td>
              <td>{statistics.in_obis_false}</td>
            </tr>
            <tr>
              <td>SOPs available</td>
              <td>{statistics.has_sops}</td>
            </tr>
          </tbody>
        </table>

      </div>
    }

    {
      keywordStatistics && <div>
        <table id="variables">
          <thead>
            <tr>
              <th>Variable</th>
              <th>Programmes</th>
            </tr>
          </thead>
          <tbody>
            {
              keywordStatistics.variables.map(stat => 
                <tr key={stat.id}>
                  <td>{stat.label}</td>
                  <td>{stat.count}</td>
                </tr>
              )
            }
          </tbody>
        </table>

        <table id="subvariables">
          <thead>
            <tr>
              <th>Subvariable</th>
              <th>Programmes</th>
            </tr>
          </thead>
          <tbody>
            {
              keywordStatistics.subvariables.map(stat => 
                <tr key={stat.id}>
                  <td>{stat.label}</td>
                  <td>{stat.count}</td>
                </tr>
              )
            }
          </tbody>
        </table>

        <table id="readiness">
          <thead>
            <tr>
              <th>Readiness requirements</th>
              <th>Programmes</th>
            </tr>
          </thead>
          <tbody>
            {
              keywordStatistics.readiness_requirements.map(stat => 
                <tr key={stat.id}>
                  <td>{stat.label}</td>
                  <td>{stat.count}</td>
                </tr>
              )
            }
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>Readiness coordination</th>
              <th>Programmes</th>
            </tr>
          </thead>
          <tbody>
            {
              keywordStatistics.readiness_coordination.map(stat => 
                <tr key={stat.id}>
                  <td>{stat.label}</td>
                  <td>{stat.count}</td>
                </tr>
              )
            }
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>Readiness data</th>
              <th>Networks</th>
            </tr>
          </thead>
          <tbody>
            {
              keywordStatistics.readiness_data.map(stat => 
                <tr key={stat.id}>
                  <td>{stat.label}</td>
                  <td>{stat.count}</td>
                </tr>
              )
            }
          </tbody>
        </table>

      </div>
    }

  </Container>
}
