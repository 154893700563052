import React from "react";
import NetworksList from "./NetworksList";
import { Switch, FormControlLabel, Button, TextField, Select, Checkbox, MenuItem, ListItemText } from "@mui/material";
import NetworksInfo from "./NetworksInfo";
import ReadinessPicker from "./ReadinessPicker";

export default function SideBar({inOBIS, handleInOBISChange, network, handleReadinessChange, handleSubvariableChange, readiness, variables, subvariables, networks, handleSetNetwork, handleVariableChange, search, handleSearchChange, datasets, handleShowDatasetsChange}) {
    return (
        <div id="sidebar" className={"overflow-y-scroll " + (network ? "hidden" : "")}>

            <div className="p-4">
                <h2>Select Essential Ocean Variables (EOVs)</h2>
                <div className="switches mb-3">
                { variables.map(x =>
                    <span key={x.id}>
                        <FormControlLabel control={
                            <Switch sx={{"& .MuiSwitch-track": { "backgroundColor": "#bbbbbb" }}} color="primary" size="small" key={x.id} name={x.id} checked={x.checked} onChange={handleVariableChange} />
                        } label={x.name} />
                        <br/>
                    </span>
                )}
                </div>
                <div>
                    <Button variant="contained" onClick={() => handleVariableChange(false)}>Deselect all</Button>
                </div>
            </div>

            <div className="px-4 py-3">
                <h2>Subvariables</h2>
                <Select
                    sx={{width: "300px", transition: "none"}}
                    size="small"
                    multiple
                    value={subvariables.filter(x => x.checked).map(x => x.id)}
                    onChange={handleSubvariableChange}
                    renderValue={(selected) => subvariables.filter(x => selected.includes(x.id)).map(x => x.name).join(", ")}
                >
                {
                    subvariables.map(x => (
                        <MenuItem key={x.id} value={x.id}>
                            <Checkbox checked={x.checked} />
                            <ListItemText primary={x.name} />
                        </MenuItem>
                    ))
                }
                </Select>
                <div className="mt-3">
                    <Button variant="contained" onClick={() => handleSubvariableChange(false)}>Deselect all</Button>
                </div>
            </div>

            <div className="px-4 py-3">
                <h2>Readiness levels</h2>
                <table className="text-xs">
                    <tbody>
                    <tr><td className="pr-4">Requirements</td><td><ReadinessPicker value={readiness[0]} onChange={(v) => handleReadinessChange(0, v)} /></td></tr>
                    <tr><td className="pr-4">Coordination</td><td><ReadinessPicker value={readiness[1]} onChange={(v) => handleReadinessChange(1, v)} /></td></tr>
                    <tr><td className="pr-4">Data</td><td><ReadinessPicker value={readiness[2]} onChange={(v) => handleReadinessChange(2, v)} /></td></tr>
                    </tbody>
                </table>
            </div>

            <div className="px-4 py-3">
                <h2>Data availability</h2>
                <div className="switches mb-3">
                    <span>
                        <FormControlLabel control={
                            <Switch sx={{"& .MuiSwitch-track": { "backgroundColor": "#bbbbbb" }}} color="primary" size="small" name="in_obis" checked={inOBIS} onChange={handleInOBISChange} />
                        } label="In OBIS" />
                    </span>
                </div>

            </div>

            <div className="border-gray-200 border-b"></div>

            <div className="px-4 py-3 pb-0 content-center">
                <NetworksInfo networks={networks} datasets={datasets} handleShowDatasetsChange={handleShowDatasetsChange} />
            </div>

            <div className="p-4 flex justify-center">
                {
                    networks && networks.length > 0 &&
                    <TextField size="small" sx={{width: "300px"}} id="outlined-basic" label="Filter by name" variant="outlined" value={search} onChange={handleSearchChange}></TextField>
                }
            </div>

            <NetworksList search={search} networks={networks} handleSetNetwork={handleSetNetwork} />

        </div>
    );
}
