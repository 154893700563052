const readinessLabels = {
    "1": "Level 1 - Idea",
    "2": "Level 2 - Documentation",
    "3": "Level 3 - Proof of concept",
    "4": "Level 4 - Trial",
    "5": "Level 5 - Verification",
    "6": "Level 6 - Operational",
    "7": "Level 7 - Fitness for purpose",
    "8": "Level 8 - Mission qualified",
    "9": "Level 9 - Sustained"
};

const readiness = function(key) {
    if (key in readinessLabels) {
        return readinessLabels[key];
    } else {
        return "";
    }
};

const getReadinessIds = function(readiness, readinessKeywords) {
    let readinessIds = [];
    let readinessTypes = ["requirements", "coordination", "data"];
    for (let i = 0; i < 3; i++) {
        if (readiness[i]) {
            let keywords = readinessKeywords.filter(k => k[0].includes("readiness/" + readinessTypes[i])).filter(k => k[0] >= "https://goosocean.org/readiness/" + readinessTypes[i] + "/" + readiness[i]);
            let levels = keywords.map(o => o[1]);
            readinessIds = readinessIds.concat(levels);
        }
    }
    return readinessIds;
}

const buildAPIQuery = function(variables, subvariables, readiness, readinessKeywords, inOBIS) {
    
    // eovs
    
    let q = "eovs=" + variables.filter(x => x.checked).map(x => x.id).join(",");

    // subvariables

    q = q + "&subvariables=" + subvariables.filter(x => x.checked).map(x => x.id).join(",");

    // readiness
    
    let readinessIds = getReadinessIds(readiness, readinessKeywords);
    q = q + "&readiness=" + readinessIds.join(",");

    // in OBIS

    if (inOBIS === true) {
        q = q + "&data_in_obis=true";
    }

    return q;
};

const buildGeoServerQuery = function(variables, subvariables, readiness, readinessKeywords, inOBIS) {

    let parts = [];

    // eovs

    const eovIds = variables.filter(x => x.checked).map(x => x.id);
    if (eovIds.length) {
        let eovPart = "array[" + eovIds.join(",") + "] && keywords";
        eovPart = eovPart.replaceAll(",", "\\,");
        parts.push("(" + encodeURIComponent(eovPart) + ")");
    }
    
    // subvariables

    const svIds = subvariables.filter(x => x.checked).map(x => x.id);
    if (svIds.length) {
        let svPart = "array[" + svIds.join(",") + "] && keywords";
        svPart = svPart.replaceAll(",", "\\,");
        parts.push("(" + encodeURIComponent(svPart) + ")");
    }
    
    // readiness

    let readinessIds = getReadinessIds(readiness, readinessKeywords);
    let readinessPart = readinessIds.map(id => id + "=any(keywords)").join(" or ");
    if (readinessPart !== "") {
        parts.push("(" + readinessPart + ")");
    }

    // in OBIS

    if (inOBIS === true) {
        parts.push("in_obis is true");
    }
    
    if (parts.length > 0) {
        return "viewparams=where:where " + parts.join(" and ");
    } else {
        return "";
    }
};

const clickBoundingBox = function(poi) {
    let {x, y, z} = poi;
    const degreesPerPixel = 360 * Math.cos(y * (Math.PI / 180)) / Math.pow(2, z + 9);
    const nPixels = 3;
    const xMin = x - nPixels * degreesPerPixel;
    const xMax = x + nPixels * degreesPerPixel;
    const yMin = y - nPixels * degreesPerPixel;
    const yMax = y + nPixels * degreesPerPixel;
    return [xMin, yMin, xMax, yMax];
};

export {
    readiness,
    readinessLabels,
    buildAPIQuery,
    buildGeoServerQuery,
    clickBoundingBox
}