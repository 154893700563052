import React from "react";
import PointOfContact from "./PointOfContact";
import { HiOutlineMap, HiLink } from "react-icons/hi";
import { Button } from "@mui/material";

export default function NetworkDetail({network, setBbox}) {

    console.log(network)

    return (
        <div>
            <h3 className="font-bold text-lg tracking-wide leading-tight">{ network.title }</h3>

            <div className="my-3" dangerouslySetInnerHTML={{__html: network.abstract}}></div>

            <div className="my-4">
                { network.url &&
                    <p className="mt-3">
                        <HiLink className="mr-2 linkicon" /> <a href={network.url} target="_blank" rel="noopener noreferrer">{network.url}</a>
                    </p>
                }
                <p><HiOutlineMap className="mr-2 linkicon" /> <a href={network.detail_url} target="_blank" rel="noopener noreferrer">Open in Geonode</a></p>
                <div className="mt-4">
                    { network.bbox_polygon && network.bbox_polygon.coordinates[0][0][0] !== 0 ?
                        <Button variant="outlined" color="dark" onClick={x => {setBbox(network.bbox_polygon)}}>Zoom</Button> :
                        <Button variant="outlined" color="error">No spatial data</Button>
                    }
                    <a className="ml-2" href={"https://github.com/iobis/bioeco-issues/issues/new?labels=content&title=" + network.title + "&body=%23%23 Network%0A%0A- Title: " + network.title + "%0A- URL: " + network.detail_url + "%0A%0A%23%23 Problems found%0A%0A[describe problems]"} target="_new" rel="noopener noreferrer"><Button variant="outlined" color="warning">Report issue</Button></a>
                </div>
            </div>

            <table className="my-4 attributes">
                <tbody>
                <tr>
                    <td>EOVs</td>
                    <td>{ network.eovs.length ? network.eovs.map(x => <span key={x.id}>{x.alt_label}<br/></span>) : "-" }</td>
                </tr>
                <tr>
                    <td>Subvariables</td>
                    <td>{ network.subvariables.length ? network.subvariables.map(x => <span key={x.id}>{x.alt_label}<br/></span>) : "-" }</td>
                </tr>
                <tr>
                    <td>Frequency</td>
                    <td>{network.maintenance_frequency ? network.maintenance_frequency : "-"}</td>
                </tr>
                <tr>
                    <td>Start date</td>
                    <td>{ network.temporal_extent_start ? network.temporal_extent_start.substring(0, 10) : "-"}</td>
                </tr>
                <tr>
                    <td>End date</td>
                    <td>{ network.temporal_extent_end ? network.temporal_extent_end.substring(0, 10) : "-"}</td>
                </tr>
                <tr>
                    <td>SOPs</td>
                    <td>{ network.sops ? network.sops.map(x => <span><a href={x} target="_blank" rel="noopener noreferrer">{x}</a><br/></span>) : "-" }</td>
                </tr>
                <tr>
                    <td>Outputs</td>
                    <td>{ network.outputs ? network.outputs.map(x => <span><a href={x} target="_blank" rel="noopener noreferrer">{x}</a><br/></span>) : "-" }</td>
                </tr>
                <tr>
                    <td>Interested in publishing to OBIS</td>
                    <td>{ network.obis_pub_interest ? "Yes" : (network.obis_pub_interest === false ? "No" : "-") }</td>
                </tr>
                <tr>
                    <td>In OBIS</td>
                    <td>{ network.data_in_obis }</td>
                </tr>
                <tr>
                    <td>License</td>
                    <td>{ network.license ? network.license.name : "-" }</td>
                </tr>
                <tr>
                    <td>Funding</td>
                    <td>{ network.funding ? network.funding : "-" }</td>
                </tr>
                <tr>
                    <td>Funding sector</td>
                    <td>{ network.get_funding_sector_display ? network.get_funding_sector_display.map(x => <span>{x}<br/></span>) : "-" }</td>
                </tr>
                <tr>
                    <td>Readiness (data)</td>
                    <td>{ network.readiness_data ? network.readiness_data : "-" }</td>
                </tr>
                <tr>
                    <td>Readiness (requirements)</td>
                    <td>{ network.readiness_requirements ? network.readiness_requirements : "-" }</td>
                </tr>
                <tr>
                    <td>Readiness (coordination)</td>
                    <td>{ network.readiness_coordination ? network.readiness_coordination : "-" }</td>
                </tr>
                <tr>
                    <td>Contact</td>
                    <td><PointOfContact poc={network.poc} /></td>
                </tr>
                {/*
                <tr>
                    <td>License</td>
                    <td>{ network.license ? network.license : "-"}</td>
                </tr>
                <tr>
                    <td>Readiness <br/>(requirements)</td>
                    <td>{ util.readiness(network.readiness_requirements) }</td>
                </tr>
                <tr>
                    <td>Readiness <br/>(coordination)</td>
                    <td>{ util.readiness(network.readiness_coordination) }</td>
                </tr>
                <tr>
                    <td>Readiness <br/>(data and information)</td>
                    <td>{ util.readiness(network.readiness_data) }</td>
                </tr>
                */}
                </tbody>
            </table>

        </div>
    );
}