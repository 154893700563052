import config from "../config";
import * as util from "./util";

const eov_ids = [
    "https://goosocean.org/eov/birds",
    "https://goosocean.org/eov/fish",
    "https://goosocean.org/eov/coral",
    "https://goosocean.org/eov/invertebrates",
    "https://goosocean.org/eov/macroalgae",
    "https://goosocean.org/eov/mammals",
    "https://goosocean.org/eov/mangrove",
    "https://goosocean.org/eov/microbes",
    "https://goosocean.org/eov/phytoplankton",
    "https://goosocean.org/eov/seagrass",
    "https://goosocean.org/eov/turtles",
    "https://goosocean.org/eov/zooplankton"
];

function isEov(id) {
    return eov_ids.includes(id);
}

function isSubvariable(id) {
    if (id.includes("https://goosocean.org/eov/")) {
        return eov_ids.some(eov_id => {
            if (id.includes(eov_id + "/")) {
                return true;
            }
            return false;
        });
    }
    return false;
}

function isDataReadiness(id) {
    return id.includes("https://goosocean.org/readiness/data");
}

function isRequirementsReadiness(id) {
    return id.includes("https://goosocean.org/readiness/requirements");
}

function isCoordinationReadiness(id) {
    return id.includes("https://goosocean.org/readiness/coordination");
}

const fetchVariables = async () => {
    const res = await fetch(config.django_api + "eovs");
    const data = await res.json();

    //const eov_regex = new RegExp(/https:\/\/goosocean.org\/eov\/[a-z]+$/, "g");
    //const subvariable_regex = new RegExp(/https:\/\/goosocean.org\/eov\/[a-z]+\/.+$/, "g");
    //const variables = data.filter(v => v.about.match(eov_regex));
    //const subvariables = data.filter(v => v.about.match(subvariable_regex));

    const variables = data.filter(v => eov_ids.includes(v.about));
    const subvariables = data.filter(v => isSubvariable(v.about));

    variables.forEach(x => {
        x.checked = false;
        x.name = x.alt_label;
    });

    subvariables.forEach(x => {
        x.checked = false;
        x.name = x.alt_label;
    });
    
    return [variables, subvariables];
};

const fetchTKeywords = async () => {
    const res = await fetch(config.django_api_internal + "thesaurus/keywords/?type=layer");
    const data = await res.json();
    const keywords = data.objects;
    const variables = keywords.filter(v => eov_ids.includes(v.about));
    const subvariables = keywords.filter(v => isSubvariable(v.about));
    // todo: refactor
    const readiness_data = keywords.filter(v => isDataReadiness(v.about));
    const readiness_coordination = keywords.filter(v => isCoordinationReadiness(v.about));
    const readiness_requirements = keywords.filter(v => isRequirementsReadiness(v.about));
    return { variables, subvariables, readiness_coordination, readiness_data, readiness_requirements };
};

const fetchReadiness = async () => {
    const res = await fetch(config.django_api + "readiness");
    const data = await res.json();
    return data.map(o => [o.about, o.id]);
};

const fetchStatistics = async () => {
    const res = await fetch(config.django_api + "layer_statistics");
    const data = await res.json();
    return data;
};

const fetchNetworks = async function(query) {
    const res = await fetch(config.django_api + "layers_minimal?page_size=10000&" + query);
    const data = await res.json();
    data.layers.forEach(x => x.checked = true);
    return data;
};

const fetchNetwork = async function(id) {
    const res = await fetch(config.django_api + "layers/" + id);
    const data = await res.json();
    const layer = data.layer;

    // add eovs property based on keywords
    //layer.eovs = layer.tkeywords.filter(tkeyword => tkeyword.about.includes("goosocean.org/eov"));
    layer.eovs = layer.tkeywords.filter(tkeyword => isEov(tkeyword.about));
    layer.subvariables = layer.tkeywords.filter(tkeyword => isSubvariable(tkeyword.about));

    // add readiness properties based on keywords
    layer.readiness_data = layer.tkeywords
        .filter(tkeyword => tkeyword.about.includes("readiness/data"))
        .map(tkeyword => tkeyword.alt_label)
        .find(e => true);
    layer.readiness_coordination = layer.tkeywords
        .filter(tkeyword => tkeyword.about.includes("readiness/coordination"))
        .map(tkeyword => tkeyword.alt_label)
        .find(e => true);
    layer.readiness_requirements = layer.tkeywords
        .filter(tkeyword => tkeyword.about.includes("readiness/requirements"))
        .map(tkeyword => tkeyword.alt_label)
        .find(e => true);

    return layer;
};

const makeWmtsUrl = function(geoServerQuery) {
    return config.geoserver_url + "/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=geonode:all_layers&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}&" + geoServerQuery;
};

const fetchNetworksForPoi = async function(poi, geoServerQuery) {
    const bb = util.clickBoundingBox(poi);
    const bbString = bb.join(",");
    const url = config.geoserver_url + "/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=geonode%3Aall_layers&maxFeatures=1000&outputFormat=application%2Fjson&BBOX=" + bbString + "&" + geoServerQuery;
    const res = await fetch(url);
    const data = await res.json();
    return data;
};

export {
    fetchVariables,
    fetchNetworksForPoi,
    fetchNetworks,
    fetchNetwork,
    makeWmtsUrl,
    fetchReadiness,
    fetchTKeywords,
    fetchStatistics
}
