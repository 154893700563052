import React from "react";
import NetworkDetail from "./NetworkDetail";

export default function DetailBar({network, variables, handleDetailBarClose, datasets, showDatasets, setBbox}) {

    return (
        <div id="detailbar" className={"overflow-y-scroll"}>
            <div className="position-absolute float-right px-2 py-1 cursor-pointer close-button" onClick={handleDetailBarClose}>×</div>
            <div>
                <div className="p-4 border-gray-200 border-b">
                    {
                        network && <NetworkDetail network={network} setBbox={setBbox} />
                    }
                </div>
            </div>
        </div>
    );
}
