import React from "react";

export default function NetworksListItem({network, handleSetNetwork}) {
    return (
        <div className="border-gray-200 border-b">
            <div className="p-4">
                <span className={"tracking-wide leading-tight cursor-pointer " + (network.highlight ? "text-primary" : "")} onClick={e => handleSetNetwork(network.pk)}>
                    { network.title }
                </span>
            </div>
        </div>
    )
}